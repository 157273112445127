* {
  box-sizing: border-box;
  overscroll-behavior: none;
}

html {
}

body {
  background-color: #f0f0f2;
}

#content {
  padding: 0.01px;
  margin: 0.01px;
  /* min-height: 600px; */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  padding-top: 1px;
  padding-bottom: 1px;

  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0;

  height: fit-content;

  text-align: center;
  background: #1abc9c;
  color: white;
  font-size: 30px;
  font-weight: bold;
  border-radius: 0 0 15px 15px;

  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.App-link {
  color: #61dafb;
}

#input {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 0.5em;

  border-radius: 5px;

  height: 45px;
  /* border:none; */
  width: 500px;
  font-size: 16px;
  /* outline: none; */

  border: none;

  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.flexbox-container {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  /* grid-template-columns: 100px 100px; 
  grid-column-gap: 5px; */
}

.flexbox-item {
  margin-left: 0.5em;
  margin-right: 0.5em;
  /* border: 1px solid black;
  background-color: #1abc9c; */
}

#typing-metrics {
  width: auto;
}

#input-stats {
  min-width: 400px;
}

.metricsItem {
  border: 1px solid black;
  width: fit-content;
}

.grid-child {
  border: 1px solid black;
  margin: 0.5em;
}

#footer {
  position: fixed;
  width: calc(100% - 2em);
  left: 0;
  bottom: 0;
  text-align: left;
  border-radius: 15px 15px 0 0;
  padding: 0 1em 1em;
  margin: 1em 1em 0;
  /* border: 1px solid black; */
  background-color: #fdfdff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

ul,
ol {
  text-align: left;
}

li:active,
li:focus {
  border: 5px solid orange;
}

.boldText {
  font-weight: bold;
}

#resultsContainer {
  padding-bottom: 12em;
}

#promptText {
  font-family: monospace;
  font-size: x-large;
}

button {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

i {
  display: block;
  line-height: 30px;
  position: relative;
}
i ::before {
  display: block;
  width: 15px;
  margin: 0 auto;
}
i .copied::after {
  position: absolute;
  top: 0;
  right: 35px;
  height: 30px;
  line-height: 25px;
  display: block;
  content: "copied";
  font-size: 1.5em;
  padding: 2px 10px;
  color: #fff;
  background-color: #4099ff;
  border-radius: 3px;
  opacity: 1;
  will-change: opacity, transform;
  animation: showcopied 1.5s ease;
}

table,
th,
td {
  /* border: 1px solid black; */
}

.monospace {
  font-family: monospace !important;
}

.large {
  font-size: large;
}

.centre {
  text-align: center;
}

.borderless {
  box-shadow: none !important;
}

.leftPadding1em {
  /* margin-left: 1em; */
  padding-left: 1em;
}

#sentenceSelector {
  border: none !important;
  /* border-radius: 15px !important; */
  /* background-color: black !important; */
  
}

#wheelPicker {
  width: auto !important;
  min-width: 400px;
  border-radius: 15px !important;
}

/* below doesn't work */
/* #wheelPicker.li::selection {
  background-color: black;
} */

.bottomBorder {
  border: 1px solid black;
}

#inactiveNotification {
  /* position: fixed; */
  /* width: 80%; */
  /* justify-content: center; */

  background: rgba(230, 48, 48, 0.85);
  /* border-radius: 15px; */

  padding: 1em;
  /* margin: 1em; */
  margin-bottom: 10px;

  font-weight: bold;

  font-size: large;
  
  color: black;

}

/* inset-0 z-50 flex items-center bg-black  mx-4 max-w-screen-sm bg-lightBrown p-1 text-md mb-8 sm:mb-8 */

.navButton {
  color: white;
  font-size: x-large;
  font-weight: bolder;
  background: none;
  border: none;
  /* border: 1px solid white; */
  /* border-radius: 5px; */

  /* margin-top: 0px !important;
  padding-top: -10px !important; */

  margin-top: -15px; /*for exact positioning */


  cursor:pointer;
}

#statusInfo {
  width: 600px;
  margin: 5em auto;
  padding: 2em;
  background-color: #fdfdff;
  border-radius: 0.5em;
  box-shadow: 2px 3px 7px 2px rgba(0,0,0,0.02);
}

td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.page-link {
  font-size: large;
  font-weight: bolder;
  /*border: 1px solid black;*/
  border-radius: 0.5em;
  padding: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background: lightblue;
}

.link {
  font-size: large;
  font-weight: bolder;
}
